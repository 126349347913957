<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <VueAwesomeSideBar
    v-model:miniMenu="miniMenu"
    v-model:collapsed="collapsed"
    :menu="sidebarItems"
    width="410px"
    width-mini-menu="60px"
    menu-type="fully"
    vue-router-enabel
    dark
    :BottomMiniMenuBtn="false"
    @item-click="actionSideBar">
    <template #header>
      <img
        v-if="miniMenu"
        class="m-1"
        src="@/assets/logoMini.svg" />
      <img
        v-else
        class="ml-auto mr-auto mt-3 mb-8 h-12 w-auto"
        src="@/assets/logo.svg" />

      <div
        class="bottomBtn"
        @click="miniMenu = !miniMenu">
        <div
          v-if="miniMenu"
          class="">
          <i
            class="pi pi-angle-double-right text-white"
            style="font-size: 2rem" />
        </div>
        <div
          v-else
          class="flex flex-row text-white">
          <i
            class="pi pi-angle-double-left"
            style="font-size: 2rem" />
          <p
            class="mt-0 ml-2"
            style="font-size: 1.25rem">
            {{ $t("actions.collapse_menu") }}
          </p>
        </div>
      </div>
    </template>
    <template #menuItemLabel="{ labelName, active }">
      <span
        v-if="sizeTextSmall(labelName) && miniMenu"
        class="text-white"
        :class="{ 'font-bold': active }"
        style="font-size: 13px">
        {{ labelName }}
      </span>
      <span
        v-else
        class="text-white"
        :class="{ 'font-bold': active }">
        {{ labelName }}
      </span>
    </template>
    <template #itemPrepandIcon="{ icon }">
      <Icon
        :icon-name="icon"
        class-attr=" h-8 w-8 text-white" />
    </template>
    <template #BottomMiniMenuBtn>
      <div
        v-if="miniMenu"
        class="text-white"
        @click="miniMenu = true">
        <i
          class="pi pi-angle-double-right"
          style="font-size: 2rem" />
      </div>
      <div
        v-else
        class="flex flex-row text-white"
        @click="miniMenu = false">
        <i
          class="pi pi-angle-double-left"
          style="font-size: 2rem" />
        <p
          class="mt-0 ml-2"
          style="font-size: 1.25rem">
          {{ $t("actions.collapse_menu") }}
        </p>
      </div>
    </template>
    <template #footer>
      <div
        v-if="!miniMenu"
        class="group ml-auto mr-auto flex items-center pb-1 px-2 leading-0 font-normal rounded-md text-utal-primary-100"
        :style="{
          'font-size': '0.6rem',
        }">
        BE: {{ $store.getters.apiVersion }} | FE: {{ $store.getters.packageVersion }}
      </div>
      <div
        v-else
        class="text-utal-primary-100 mini-version-footer pb-2 font-bold mx-1">
        <p>BE: {{ $store.getters.apiVersion }}</p>
        <p>FE: {{ $store.getters.packageVersion }}</p>
      </div>
    </template>
  </VueAwesomeSideBar>
</template>

<script>
import { uspAPI } from "@/api/axios-base";
import "vue-awesome-sidebar/dist/vue-awesome-sidebar.css";
import Icon from "@/components/icons/Icon.vue";

export default {
  name: "SideBarNav",
  components: { Icon },
  emits: ["onChangedWidth"],
  data() {
    return {
      nav_user_permissions: [],
      miniMenu: true,
      collapsed: false,
    };
  },
  computed: {
    DOC_URL() {
      return process.env.VUE_APP_API_URL + "/sphinxdoc/" + this.$i18n.locale.split("-")[0] + "/index.html";
    },
    sidebarItems() {
      return this.sidebarData.filter((x) => x.show);
    },
    sidebarData() {
      return [
        {
          name: this.$t("orders.complete_orders"),
          icon: "nav.complete_plates",
          href: "/nplateorders",
          show: this.$store.state.is_complete_plate_manufacturer || this.$store.state.is_issuing_authority,
        },
        {
          name: this.$t("orders.raw_orders"),
          icon: "nav.raw_plates",
          href: "/orders",
          show: this.$store.state.is_complete_plate_manufacturer || this.$store.state.is_raw_plate_manufacturer,
        },
        {
          name: this.$t("orders.internal_orders"),
          icon: "nav.internal_raw_plates",
          href: "/internal-orders",
          show: this.$store.state.is_raw_plate_manufacturer,
        },
        {
          name: this.$t("scrap_orders.scrap_orders"),
          icon: "nav.scrap_plates",
          href: "/scrap-orders",
          show: this.$store.state.is_complete_plate_manufacturer || this.$store.state.is_issuing_authority,
        },
        {
          name: this.$t("menu.organization"),
          icon: "nav.organization",
          show:
            this.nav_user_permissions.includes("ORGANIZATIONS_MANAGE_ORG_PROFILE") ||
            this.nav_user_permissions.includes("ORGANIZATIONS_MANAGE_ORG_DELIVERY_ADDRESSES") ||
            this.nav_user_permissions.includes("ORGANIZATIONS_MANAGE_ORG_USERS") ||
            this.nav_user_permissions.includes("ORGANIZATIONS_MANAGE_FOREIGN_USERS") ||
            this.$store.state.is_staff,
          children: [
            {
              name: this.$t("menu.organization_profile"),
              icon: "nav.organization_profile",
              href: "/organization/organization",
              show:
                this.nav_user_permissions.includes("ORGANIZATIONS_MANAGE_ORG_PROFILE") || this.$store.state.is_staff,
            },
            {
              name: this.$t("menu.organization_delivery_addrs"),
              icon: "nav.organization_addresses",
              href: "/organization/delivery-addresses",
              show:
                this.nav_user_permissions.includes("ORGANIZATIONS_MANAGE_ORG_DELIVERY_ADDRESSES") ||
                this.$store.state.is_staff,
            },
            {
              name: this.$t("menu.organization_users"),
              icon: "nav.organization_users",
              href: "/organization/users-data",
              show: this.nav_user_permissions.includes("ORGANIZATIONS_MANAGE_ORG_USERS") || this.$store.state.is_staff,
            },
            {
              name: this.$t("organizations.foreign_users"),
              icon: "nav.organization_foreign_users",
              href: "/organization/users-foreign",
              show:
                false &&
                (this.nav_user_permissions.includes("ORGANIZATIONS_MANAGE_FOREIGN_USERS") ||
                  this.$store.state.is_staff),
            },
            {
              name: this.$t("organizations.organizations"),
              icon: "nav.organization_create",
              href: "/organization/organization-list",
              show: this.$store.state.is_staff,
            },
          ].filter((x) => x.show),
        },
        {
          name: this.$t("stock.stock_raw"),
          show: this.$store.state.is_complete_plate_manufacturer || this.$store.state.is_raw_plate_manufacturer,
          href: "/stock",
          icon: "nav.stock_raw",
        },
        {
          name: this.$t("contracts.contracts"),
          icon: "nav.contracts",
          href: "/contracts",
          show: this.$store.state.is_requires_contracts,
        },
        {
          name: this.$t("search.search"),
          show:
            this.$store.state.is_issuing_authority ||
            this.$store.state.is_complete_plate_manufacturer ||
            this.$store.state.is_staff,
          href: "/search",
          icon: "nav.search",
        },
        {
          name: this.$t("used_numbers.used_numbers"),
          show: this.$store.state.is_issuing_authority,
          href: "/used-number-ranges",
          icon: "nav.used_numbers",
        },
        {
          name: this.$t("reserved_numbers.reserved_numbers"),
          show: this.$store.state.is_issuing_authority,
          href: "/reserved-number-ranges",
          icon: "nav.reserved_numbers",
        },
        {
          show: this.$store.state.is_raw_plate_manufacturer,
          icon: "nav.stock_material",
          href: "/material-stock",
          name: this.$t("stock.stock_material"),
        },
        {
          show: this.$store.state.is_raw_plate_manufacturer,
          href: "/acquisitions",
          icon: "nav.stock_acquisition",
          name: this.$t("acquisitions.acquisitions"),
        },
        {
          show: this.$store.state.is_complete_plate_manufacturer || this.$store.state.is_raw_plate_manufacturer,
          href: "/stock-incoming",
          icon: "nav.stock_incoming",
          name: this.$t("stock.stock_incoming"),
        },
        {
          show: this.$store.state.is_complete_plate_manufacturer || this.$store.state.is_raw_plate_manufacturer,
          href: "/stock-outgoing",
          icon: "nav.stock_outgoing",
          name: this.$t("stock.stock_outgoing"),
        },
        {
          name: this.$t("reports.reports"),
          icon: "nav.reports",
          show:
            this.$store.state.is_complete_plate_manufacturer ||
            this.$store.state.is_raw_plate_manufacturer ||
            this.$store.state.is_issuing_authority,
          children: [
            {
              show: this.$store.state.is_complete_plate_manufacturer || this.$store.state.is_raw_plate_manufacturer,
              href: "/reports/production-report",
              icon: "nav.reports_production",
              name: this.$t("reports.production_report"),
            },
            {
              show: this.$store.state.is_issuing_authority,
              href: "/reports/individual-license-report",
              icon: "nav.reports_vanity",
              name: this.$t("reports.individual_report"),
            },
            {
              show: this.$store.state.is_issuing_authority,
              href: "/reports/registration-plates-report",
              icon: "nav.reports_indicators",
              name: this.$t("reports.registration_report"),
            },
            {
              show: this.$store.state.is_complete_plate_manufacturer,
              href: "/reports/production-plates-report",
              icon: "nav.reports_indicators",
              name: this.$t("reports.production_plates_report"),
            },
            {
              show: this.$store.state.is_complete_plate_manufacturer,
              href: "/reports/production-plate-wastes-report",
              icon: "nav.reports_indicators",
              name: this.$t("reports.production_plate_wastes_report"),
            },
          ].filter((x) => x.show),
        },
        {
          name: this.$t("menu.help"),
          show: true,
          icon: "nav.help",
          children: [
            {
              show: true,
              icon: "nav.help_manual",
              name: this.$t("menu.documentation"),
              href: "#",
              link: this.DOC_URL,
            },
            {
              show: true,
              icon: "nav.help_changelog",
              name: this.$t("menu.changelog"),
              href: "/changelog",
            },
            {
              show: true,
              href: "/issue-comment/list",
              icon: "action.send",
              name: this.$t("issue_comment.title"),
            },
            {
              show: true,
              href: "/license",
              icon: "menu.legal",
              name: this.$t("menu.license"),
            },
            {
              show: true,
              href: "/privacy",
              icon: "menu.legal",
              name: this.$t("menu.privacy"),
            },
          ].filter((x) => x.show),
        },
      ];
    },
  },
  watch: {
    miniMenu(newValue) {
      this.$emit("onChangedWidth", newValue);
      this.$store.dispatch("changeSideBar", !newValue);
    },
  },
  mounted() {
    this.getUserPermissions(1);
    this.is_complete_plate_manufacturer = this.$store.state.is_complete_plate_manufacturer;
    this.is_raw_plate_manufacturer = this.$store.state.is_raw_plate_manufacturer;
    this.is_issuing_authority = this.$store.state.is_issuing_authority;
  },
  methods: {
    sizeTextSmall(text) {
      return text.length > 30;
    },
    actionSideBar(item) {
      if (item.link) window.open(item.link, "_blank");
      // if (!item.children) this.miniMenu = true;
    },
    goToUrl(item) {
      if (item.to) {
        this.$router.push(item.to);
        this.$store.state.openSidebar = false;
      }
      if (item.externalURL) {
        window.open(item.externalURL, "_blank");
        this.$store.state.openSidebar = false;
      }
    },
    getUserPermissions(page) {
      uspAPI
        .get(
          "api/organizations/permission-grant/?context=" +
            this.$store.state.organization_id +
            "&user=" +
            this.$store.state.user_id +
            "&page=" +
            page,
          { headers: { Authorization: `Bearer ${this.$store.state.accessToken}` } }
        )
        .then((response) => {
          this.nav_user_permissions = this.nav_user_permissions.concat(response.data.results);
          if (response.data.next != null) {
            this.getUserPermissions(page + 1);
          } else {
            var nav_perm_old = this.nav_user_permissions;
            this.nav_user_permissions = [];
            for (var permission of nav_perm_old) {
              this.nav_user_permissions.push(permission.permission_identifier);
            }
          }
        })
        .catch(); // NOHANDLER: This has ALLOW_ALL permission
    },
  },
};
</script>
<style>
.vas-menu {
  background-color: rgb(235, 0, 0) !important;
}
.miniCoolapseMenu {
  color: black !important;
  background-color: rgb(233, 15, 15) !important;
}
.dark-theme {
  color: black !important;
  background-color: rgb(235, 0, 0) !important;
}
.bottomBtn {
  color: black !important;
  background-color: rgb(233, 15, 15) !important;
}
.menu-item-base {
  color: black !important;
  background-color: rgb(235, 0, 0) !important;
}
.menu-wraper {
  color: black !important;
  background-color: rgb(235, 0, 0, 0.8) !important;
}
.menuexpand {
  color: white !important;
  background-color: red !important;
}
.activeClass {
  color: white !important;
  background-color: red !important;
}
.labelHoverClass {
  background-color: rgb(233, 15, 15) !important;
  color: white !important;
  padding-left: 0px !important;
}

.topContainer {
  color: white !important;
  background-color: rgb(233, 15, 15) !important;
}
.labelMini {
  background-color: rgb(233, 15, 15) !important;
}

.vas-menu.dark-theme .label.activeClass::before {
  background-color: white;
}
.mini-version-footer {
  font-size: 6pt;
}
</style>
